import {Injectable} from '@angular/core';
import {HttpService} from "../../../@canguru/services/http.service";
import {Observable} from "rxjs";
import {api} from "../config/api";
import {CharityItem, CharityResponse, CharityUpdateResponse} from "../interfaces/api/content/charities";
import {UtilService} from "./util.service";

@Injectable({
  providedIn: 'root'
})
export class CharityService {
  isLoading = false;
  data: CharityItem[] = [];
  isError = false;
  constructor(private http: HttpService, private utilService: UtilService) {
  }

  /**
   * Get charities list.
   */
  getCharities(): Observable<CharityResponse> {
    return this.http.get(api.charities) as Observable<CharityResponse>;
  }

  /**
   * Create/Update charity
   * @param id
   * @param form
   */
  updateCharity(id: number|undefined, form: FormData): Observable<CharityUpdateResponse> {
    return this.http.post(id ? `${api.updateCharity}${id}` : api.createCharity, form) as Observable<CharityUpdateResponse>;
  }

  /**
   * Remove charity
   * @param id
   */
  removeCharity(id: number) {
    return this.http.delete(`${api.removeCharity}${id}`) as Observable<CharityUpdateResponse>;
  }


  /**
   * Get charity list and end loader.
   */
  getCharitiesList() {
    this.isLoading = true;
    this.isError = false;
    this.getCharities().subscribe({
      next: (res) => {
        if (res.result === 'success') {
          this.data = res.content;
        }
      }, error: (err) => {
        this.isError = true;
        if (!this.utilService.isProduction) console.log(err);
      }
    }).add(() => {
      this.isLoading = false;
    });
  }
}
