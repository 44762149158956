import { Injectable } from '@angular/core';
import {HttpService} from "../../../@canguru/services/http.service";
import {Observable} from "rxjs";
import {api} from "../config/api";
import {EmailForm, EmailResponse} from '../interfaces/api/content/email';

@Injectable({
  providedIn: 'root'
})
export class SendEmailService {

  constructor(private http: HttpService) {
  }

  /**
   * Send Email.
   */
  sendEmail(form: EmailForm): Observable<EmailResponse> {
    return this.http.post(api.sendEmail, form) as Observable<EmailResponse>;
  }
}
