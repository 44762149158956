import {Injectable} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  isProduction = environment.production;
  constructor(private snackbar: MatSnackBar) {
  }

  displayToast(message: string, action: string = 'Close', duration: number = 4000): void {
    this.snackbar.open(message, action, {
      duration,
      panelClass: "toast-dialog"
    });
  }

  showConnectionErrorToast() {
    this.displayToast('Something went wrong, Please try again later.');
  }

  convertToFormData(form: any): FormData {
    const formData = new FormData();
    Object.keys(form).forEach(key => {
      if (typeof form[key] === 'object') {
        formData.append(key, form[key].file, form[key].name);
      } else {
        formData.append(key, form[key]);
      }
    });
    return formData;
  }

  capitalizeFirstLetter(word: string): string {
    if (!word) return '';
    return word?.charAt(0)?.toUpperCase() + word?.slice(1);
  }
}
