import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {api} from "../config/api";
import {HttpService} from "../../../@canguru/services/http.service";
import {ResetPasswordResponse} from "../interfaces/api/content/resetPassword";

class ResetPasswordForm {
}

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  constructor(private http: HttpService) {
  }

  /**
   * Reset password.
   */
  resetPassword(form: ResetPasswordForm): Observable<ResetPasswordResponse> {
    return this.http.post(api.resetPassword, form) as Observable<ResetPasswordResponse>;
  }
}
