import {EventEmitter, Injectable} from '@angular/core';
import { NavigationDropdown, NavigationItem, NavigationLink, NavigationSubheading } from '../interfaces/navigation-item.interface';
import { Subject } from 'rxjs';
import {bottomSideMenu, productionSideMenu, sideMenu} from "../static/sideMenu";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  items: NavigationItem[];
  bottomItems: NavigationItem[] = bottomSideMenu;
  isMobile = false;
  height = 0;
  toggleMenu: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _openChangeSubject = new Subject<NavigationDropdown>();
  openChange$ = this._openChangeSubject.asObservable();

  constructor() {
    this.items = environment.production ? productionSideMenu : sideMenu;
  }

  triggerOpenChange(item: NavigationDropdown) {
    this._openChangeSubject.next(item);
  }

  isLink(item: NavigationItem): item is NavigationLink {
    return item.type === 'link';
  }

  isDropdown(item: NavigationItem): item is NavigationDropdown {
    return item.type === 'dropdown';
  }

  isSubheading(item: NavigationItem): item is NavigationSubheading {
    return item.type === 'subheading';
  }

  getMenuChangeEmitter() {
    return this.toggleMenu;
  }
}
