import {Component, HostListener, Injector, OnInit} from '@angular/core';
import {BaseComponent} from "../components/base/base.component";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent extends BaseComponent implements OnInit {
  isSideMenuOpened = false;
  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.navigationService.getMenuChangeEmitter().subscribe(res => {
      this.isSideMenuOpened = res;
    })
  }


  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: Event) {
    this.navigationService.isMobile = window.innerWidth < 800;
    this.navigationService.height = window.innerHeight;
  }

  onCloseSideMenu() {
    this.navigationService.getMenuChangeEmitter().emit(false);
  }
}
