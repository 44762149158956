import { Injectable } from '@angular/core';
import {HttpService} from "../../../@canguru/services/http.service";
import {Observable} from "rxjs";
import {api} from "../config/api";
import {BlogDetailsResponse, BlogForm, BlogsResponse} from "../interfaces/api/content/blogs";
import {MainResponse} from "../interfaces/api/response";

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private http: HttpService) { }
  /**
   * Get Blogs list.
   */
  getBlogs(page: number, search: string, pageSize: number): Observable<BlogsResponse> {
    return this.http.get(`${api.blogs}?page=${page}&search=${search}&per_page=${pageSize}`) as Observable<BlogsResponse>;
  }

  /**
   * Update Blog
   * @param form
   */
  updateBlog(form: any, id: number): Observable<MainResponse> {
    return this.http.post(`${api.editBlog}/${id}`, form);
  }

  /**
   * Add new blog
   * @param form
   */
  addBlog(form: FormData): Observable<MainResponse> {
    return this.http.post(api.addBlog, form);
  }

  /**
   * Get blog details
   * @param id
   */
  getBlogDetails(id: number): Observable<BlogDetailsResponse> {
    return this.http.get(`${api.getBlog}/${id}`);
  }

  /**
   * Remove blog
   * @param id
   */
  removeBlog(id: number): Observable<MainResponse> {
    return this.http.delete(`${api.removeBlog}/${id}`);
  }
}
