import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AllAttributes, AllInteractions, CurrentUser, User} from '../interfaces/user';
import {keys} from '../../../@canguru/config/keys';
import {api} from "../config/api";
import {LoginResponse} from "../interfaces/api/auth/loginResponse";
import {Observable} from "rxjs";
import {StorageService} from "./storage.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  currentUser: CurrentUser | undefined;

  constructor(private http: HttpClient, private storageService: StorageService) {
    this.initUser();
  }

  /**
   * init user.
   */
  initUser() {
    const user = this.storageService.getItem(keys.user);
    if (user) {
      this.currentUser = JSON.parse(user);
    }
  }

  /**
   * Login and start session.
   * @param token
   * @param user
   * @param attributes
   * @param interactions
   */
  async start_session(token: string, user: User, attributes?: AllAttributes, interactions?: any) {
    const temp = {...user} as CurrentUser;
    temp['token'] = token;
    temp['all_attributes'] = attributes;
    temp['all_interaction'] = interactions;
    this.storageService.setItem(keys.user, JSON.stringify(temp));
    this.storageService.setItem(keys.loginTime, new Date() + '');
    this.currentUser = temp;
  }

  /**
   * Logout and destroy session.
   */
  public destroy_session(): void {
    this.storageService.remove(keys.user);
    this.storageService.remove(keys.loginTime);
    this.currentUser = undefined;
  }

  /**
   * Login
   * @param data
   */
  login(data: any): Observable<LoginResponse> {
    return this.http.post(api.login, data) as Observable<LoginResponse>;
  }

  /**
   * Get token from user.
   * @private
   */
  private getToken(): string {
    if (this.currentUser && this.currentUser.token) {
      return this.currentUser.token;
    }
    return '';
  }

  /**
   * Check if user authenticated.
   */
  public isAuthenticated(): boolean {
    return !!this.getToken();
  }
}
