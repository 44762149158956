import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {ProfeshHttpInterceptor} from "../@canguru/services/http.interceptor";
import {CanguruModule} from "../@canguru/canguru.module";
import { AuthService } from './shared/services/auth.service';
import { UtilService } from './shared/services/util.service';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {AuthGuardService} from "./shared/guard/auth-guard.service";
import {NavigationService} from "../@canguru/services/navigation.service";
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    CanguruModule,
    MatSnackBarModule,
  ],
  providers: [
    AuthService,
    UtilService,
    AuthGuardService,
    NavigationService,
    {provide: HTTP_INTERCEPTORS, useClass: ProfeshHttpInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
