import { Injectable } from '@angular/core';
import {HttpService} from "../../../@canguru/services/http.service";
import {Observable} from "rxjs";
import {api} from "../config/api";
import {NotificationForm, NotificationResponse} from '../interfaces/api/content/notifications';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpService) {
  }

  /**
   * Send notification.
   */
  sendNotification(form: NotificationForm): Observable<NotificationResponse> {
    return this.http.post(api.sendNotification, form) as Observable<NotificationResponse>;
  }
}
