import {environment} from "../../../environments/environment";

const baseUrl = environment.globalServerURL;

export const api = {
  login: `${baseUrl}login`,
  dashboard: `${baseUrl}summary`,
  users: `${baseUrl}users`,
  activateUser: `${baseUrl}activate`,
  banUser: `${baseUrl}ban`,
  deleteUser: `${baseUrl}users/destroy`,
  interactions: `${baseUrl}users/interactions`,
  charities: `${baseUrl}charities`,
  createCharity: `${baseUrl}charity/store`,
  updateCharity: `${baseUrl}charity/update/`,
  removeCharity: `${baseUrl}charity/destroy/`,
  preferences: `${baseUrl}setting`,
  updatePreferences: `${baseUrl}setting/update`,
  verifyUser: `${baseUrl}users/verify`,
  resetPassword: `${baseUrl}users/resetPassword`,
  removeInteraction: `${baseUrl}users/interactions/destroy/`,
  destroyInteractions: `${baseUrl}users/interactions/destroy/all`,
  sendNotification: `${baseUrl}send-notification`,
  sendEmail: `${baseUrl}send-email`,
  unlockUser: `${baseUrl}users/unlock`,
  blogs: `${baseUrl}blogs`,
  addBlog: `${baseUrl}blog/store`,
  editBlog: `${baseUrl}blog/update`,
  removeBlog: `${baseUrl}blog/destroy`,
  getBlog: `${baseUrl}blog`,
  getMobileLinks: `${baseUrl}config/links`,
  updateMobileLinks: `${baseUrl}config/links/update`
}
