import {Component, Injector, OnInit} from '@angular/core';
import {fadeInUp400ms} from "../../animations/fade-in-up.animation";
import {fadeInRight400ms} from "../../animations/fade-in-right.animation";
import {iconsIC} from "../../static/icons-ic";
import {BaseComponent} from "../../components/base/base.component";

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [fadeInRight400ms, fadeInUp400ms]
})
export class SidenavComponent extends BaseComponent implements OnInit {
  icLogout = iconsIC.exit_to_app;
  icProfile = iconsIC.account_circle;
  icDropDown = iconsIC.arrow_drop_down;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
  }

  /**
   * Destroy session and navigate to login page.
   */
  logout() {
    this.authService.destroy_session();
    this.router.navigateByUrl('login');
  }
}
