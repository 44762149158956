import {Injectable} from '@angular/core';
import {HttpService} from "../../../@canguru/services/http.service";
import {Observable} from "rxjs";
import {api} from "../config/api";
import {InteractionItem, InteractionsResponse} from "../interfaces/api/content/interactions";
import {MatTableDataSource} from "@angular/material/table";
import {interactions} from "../static/interactiosn";
import {UtilService} from "./util.service";
import {iconsIC} from "../../../@canguru/static/icons-ic";

@Injectable({
  providedIn: 'root'
})
export class InteractionsService {
  interactionTableData: MatTableDataSource<InteractionItem> = new MatTableDataSource();
  isLoading = false;
  isError = false;
  currentPage = 0;
  totalData = 0;
  pageSize = 10;
  searchTerm = '';
  filtersList: {title: string, value: number|string}[] = [];
  selectedFilter: string|number = '';
  icFilterSelected = iconsIC.check_box;
  icFilterDefault = iconsIC.check_box_outline_blank;
  sortBy: string = '';
  order: string = '';

  constructor(private http: HttpService, private utilService: UtilService) {
  }

  /**
   * Get interactions list.
   */
  getInteractions(page: number, search: string, pageSize: number): Observable<InteractionsResponse> {
    return this.http.get(`${api.interactions}?page=${page}&search=${search}&per_page=${pageSize}&interaction_id=${this.selectedFilter}&sort_by=${this.sortBy}&order=${this.order}`) as Observable<InteractionsResponse>;
  }

  /**
   * Get interactions list.
   * @param loadMore
   */
  getInteractionsData(loadMore = false) {
    this.isLoading = true;
    this.isError = false;
    this.getInteractions((this.currentPage + 1), this.searchTerm, this.pageSize).subscribe({
      next: (res) => {
        if (res.result === 'success') {
          if (!res.content?.data && !loadMore) {
            this.interactionTableData.data = [];
            return;
          }
          this.interactionTableData.data = loadMore ? [...this.interactionTableData.data, ...res.content.data] : res.content.data;
          this.totalData = res.content.total;
          if (loadMore) this.currentPage = this.currentPage + 1;
        }
      },
      error: (err) => {
        this.isError  = true;
        if (!this.utilService.isProduction) console.log(err);
      }
    }).add(() => {
      this.isLoading = false;
    });
  }

  /**
   * Reset data.
   */
  reset() {
    this.interactionTableData = new MatTableDataSource();
    this.currentPage = 0;
    this.totalData = 0;
    this.pageSize = 10;
    this.searchTerm = '';
  }

  /**
   * get class by type.
   * @param type
   */
  getClassByType(type: 'like' | 'match' | 'block' | 'dislike' | 'report' | 'unmatch' | 'favourite' | any) {
    return interactions[type as 'like' | 'match' | 'block' | 'dislike' | 'report' | 'unmatch' | 'favourite']?.className;
  }

  /**
   * Get icon by type.
   * @param type
   */
  getIconByType(type: 'like' | 'match' | 'block' | 'dislike' | 'report' | 'unmatch' | 'favourite' | any) {
    return interactions[type as 'like' | 'match' | 'block' | 'dislike' | 'report' | 'unmatch' | 'favourite']?.icon;
  }

  /**
   * Remove interaction using id.
   * @param id
   */
  removeInteraction(id: number) {
    this.isLoading = true;
    this.http.delete(`${api.removeInteraction}${id}`).subscribe({
      next: (res) => {
        if (res.result === 'success') {
          const temp = [...this.interactionTableData.data];
          const idx = temp.findIndex(item => item.id === id);
          temp.splice(idx, 1);
          this.interactionTableData.data = temp;
          this.utilService.displayToast('Interaction removed successfully!');
        }
      }, error: (err) => {
        if (!this.utilService.isProduction) console.log(err);
      }
    }).add(() => {
      this.isLoading = false;
    });
  }

  /**
   * Remove all interactions.
   */
  destroyAll() {
    this.isLoading = true;
    this.http.delete(api.destroyInteractions).subscribe({
      next: (res) => {
        if (res.result === 'success') {
          this.interactionTableData.data = [];
          this.utilService.displayToast('Interactions removed successfully!');
        }
      }, error: (err) => {
        if (!this.utilService.isProduction) console.log(err);
      }
    }).add(() => {
      this.isLoading = false;
    });
  }



  /**
   * Filter data by interaction type.
   * @param filter
   */
  filterData(filter: string|number) {
    this.currentPage = 0;
    this.selectedFilter = filter;
    this.getInteractionsData();
  }


  /**
   * Get filter menu icon by selected item.
   * @param item
   */
  getIconByFilterName(item: string|number) {
    if (item === this.selectedFilter) return this.icFilterSelected;
    return this.icFilterDefault;
  }
}
