import {iconsIC} from "../../../@canguru/static/icons-ic";

export const interactions = {
  like: {
    className: 'like',
    icon: iconsIC.thumb_up
  },
  match: {
    className: 'match',
    icon: iconsIC.favorite
  },
  block: {
    className: 'block',
    icon: iconsIC.block
  },
  dislike: {
    className: 'dislike',
    icon: iconsIC.thumb_down
  },
  report: {
    className: 'report',
    icon: iconsIC.report
  },
  unmatch: {
    className: 'unmatch',
    icon: iconsIC.close
  },
  favourite: {
    className: 'favourite',
    icon: iconsIC.star
  }
}
