import {Injectable} from '@angular/core';
import * as CryptoJS from 'crypto-js';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() {
  }

  generateEncryptionKey() {
    const hashedInput = CryptoJS.SHA256(environment.key).toString();
    const encryptionKey = hashedInput.slice(0, 32);
    return encryptionKey;
  };

  /**
   * Set item
   * @param key
   * @param value
   */
  setItem(key: string, value: string) {
    const encryptionKey = this.generateEncryptionKey();
    const encryptedData = CryptoJS.AES.encrypt(value, encryptionKey).toString();
    localStorage.setItem(key, encryptedData);
  }

  /**
   * Get item
   * @param key
   */
  getItem(key: string) {
    try {
      const encryptionKey = this.generateEncryptionKey();
      const encryptedData = localStorage.getItem(key);
      if (!encryptedData) return null;
      return CryptoJS.AES.decrypt(encryptedData, encryptionKey).toString(CryptoJS.enc.Utf8);
    } catch (e) {
      return '';
    }
  }

  /**
   * Remove item
   * @param key
   */
  remove(key: string) {
    localStorage.removeItem(key);
  }
}
