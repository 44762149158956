import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {ChangeDetectorRef, Injector, NgZone, Renderer2} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from "@angular/material/dialog";
import {AuthService} from "../../../app/shared/services/auth.service";
import {UtilService} from "../../../app/shared/services/util.service";
import {NavigationService} from "../../services/navigation.service";
import {DashboardService} from "../../../app/shared/services/dashboard.service";
import {UsersService} from "../../../app/shared/services/users.service";
import {InteractionsService} from "../../../app/shared/services/interactions.service";
import {CharityService} from "../../../app/shared/services/charity.service";
import {PreferencesService} from "../../../app/shared/services/preferences.service";
import {ResetPasswordService} from "../../../app/shared/services/reset-password.service";
import {NotificationService} from "../../../app/shared/services/notification.service";
import {SendEmailService} from "../../../app/shared/services/send-email.service";
import {BlogService} from "../../../app/shared/services/blog.service";
import {LoadingService} from "../../../app/shared/services/loading.service";
import {MobileLinksService} from "../../../app/shared/services/mobile-links.service";

export class BaseComponent {
    public router: Router;
    public fb: FormBuilder;
    public cdk: ChangeDetectorRef;
    public ngZone: NgZone;
    public translateService: TranslateService;
    public route: ActivatedRoute;
    public dialog: MatDialog;
    public authService: AuthService;
    public utilService: UtilService;
    public navigationService: NavigationService;
    public dashboardService: DashboardService;
    public usersService: UsersService;
    public interactionService: InteractionsService;
    public charityService: CharityService;
    public preferencesService: PreferencesService;
    public renderer: Renderer2;
    public resetPasswordService: ResetPasswordService;
    public notificationService: NotificationService;
    public sendEmailService: SendEmailService
    public blogService: BlogService
    public loadingService: LoadingService;
    public mobileLinksService: MobileLinksService;

    protected constructor(protected injector: Injector) {
        this.router = this.injector.get(Router);
        this.fb = this.injector.get(FormBuilder);
        this.cdk = this.injector.get(ChangeDetectorRef);
        this.ngZone = this.injector.get(NgZone);
        this.translateService = this.injector.get(TranslateService);
        this.route = this.injector.get(ActivatedRoute);
        this.dialog = this.injector.get(MatDialog);
        this.authService = this.injector.get(AuthService);
        this.utilService = this.injector.get(UtilService);
        this.navigationService = this.injector.get(NavigationService);
        this.dashboardService = this.injector.get(DashboardService);
        this.usersService = this.injector.get(UsersService);
        this.interactionService = this.injector.get(InteractionsService);
        this.charityService = this.injector.get(CharityService);
        this.preferencesService = this.injector.get(PreferencesService);
        this.renderer = this.injector.get(Renderer2);
        this.resetPasswordService = this.injector.get(ResetPasswordService);
        this.notificationService = this.injector.get(NotificationService);
        this.sendEmailService = this.injector.get(SendEmailService);
        this.blogService = this.injector.get(BlogService);
        this.loadingService = this.injector.get(LoadingService);
        this.mobileLinksService = this.injector.get(MobileLinksService);
    }
}
