import {NavigationItem} from "../interfaces/navigation-item.interface";
import {iconsIC} from "./icons-ic";

export const sideMenu: NavigationItem[] = [
  {
    type: 'link',
    label: 'menu.dashboard',
    route: '/admin/dashboard',
    icon: iconsIC.dashboard,
  },
  {
    type: 'dropdown',
    label: 'menu.users',
    icon: iconsIC.contacts,
    children: [
      {
        type: 'link',
        label:  'menu.list',
        route: '/admin/users',
        routerLinkActive: {exact: true},
        icon: iconsIC.contacts,
      },
      {
        type: 'link',
        label: 'menu.resetPassword',
        route: '/admin/users/resetPassword',
        routerLinkActive: {exact: false},
        icon: iconsIC.contacts,
      }
    ]
  },
  {
    type: 'link',
    label: 'menu.interactions',
    route: '/admin/interactions',
    icon: iconsIC.favorite,
  },
  {
    type: 'dropdown',
    label: 'menu.blogs',
    icon: iconsIC.featured_play_list,
    children: [
      {
        type: 'link',
        label:  'menu.list',
        route: '/admin/blogs',
        routerLinkActive: {exact: true},
        icon: iconsIC.contacts,
      },
      {
        type: 'link',
        label: 'menu.addBlog',
        route: '/admin/blogs/add',
        routerLinkActive: {exact: false},
        icon: iconsIC.contacts,
      }
    ]
  },
  {
    type: 'link',
    label: 'menu.charities',
    route: '/admin/charities',
    icon: iconsIC.library_add,
  },
  {
    type: 'link',
    label: 'menu.notifications',
    route: '/admin/notifications',
    icon: iconsIC.notifications,
  },
  {
    type: 'link',
    label: 'menu.sendEmail',
    route: '/admin/sendEmail',
    icon: iconsIC.email,
  },
  {
    type: 'link',
    label: 'menu.mobileLinks',
    route: '/admin/mobileLinks',
    icon: iconsIC.link,
  },
];

export const bottomSideMenu: NavigationItem[] = [{
    type: 'link',
    label: 'menu.preferences',
    route: '/admin/preferences',
    icon: iconsIC.settings,
  },
]


export const productionSideMenu: NavigationItem[]  = [
  {
    type: 'link',
    label: 'menu.dashboard',
    route: '/admin/dashboard',
    icon: iconsIC.dashboard,
  },
  {
    type: 'dropdown',
    label: 'menu.users',
    icon: iconsIC.contacts,
    children: [
      {
        type: 'link',
        label:  'menu.list',
        route: '/admin/users',
        routerLinkActive: {exact: true},
        icon: iconsIC.contacts,
      },
      {
        type: 'link',
        label: 'menu.resetPassword',
        route: '/admin/users/resetPassword',
        routerLinkActive: {exact: false},
        icon: iconsIC.contacts,
      }
    ]
  },
  {
    type: 'link',
    label: 'menu.interactions',
    route: '/admin/interactions',
    icon: iconsIC.favorite,
  },
  {
    type: 'dropdown',
    label: 'menu.blogs',
    icon: iconsIC.featured_play_list,
    children: [
      {
        type: 'link',
        label:  'menu.list',
        route: '/admin/blogs',
        routerLinkActive: {exact: true},
        icon: iconsIC.contacts,
      },
      {
        type: 'link',
        label: 'menu.addBlog',
        route: '/admin/blogs/add',
        routerLinkActive: {exact: false},
        icon: iconsIC.contacts,
      },
    ]
  },
  {
    type: 'link',
    label: 'menu.mobileLinks',
    route: '/admin/mobileLinks',
    icon: iconsIC.link,
  },
]
