import {Injectable} from '@angular/core';
import {HttpService} from "../../../@canguru/services/http.service";
import {Observable} from "rxjs";
import {api} from "../config/api";
import {
  PreferencesForm,
  PreferencesItem,
  PreferencesResponse,
  UpdatePreferencesResponse
} from "../interfaces/api/content/preferences";

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  constructor(private http: HttpService) {
  }

  /**
   * Get config data.
   */
  getConfig(): Observable<PreferencesResponse> {
    return this.http.get(api.preferences) as Observable<PreferencesResponse>;
  }

  /**
   * Update config data.
   */
  updateConfig(form: PreferencesForm|PreferencesItem): Observable<UpdatePreferencesResponse> {
    return this.http.post(api.updatePreferences, form) as Observable<UpdatePreferencesResponse>;
  }
}
