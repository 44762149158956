import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs";
import {keys} from "../@canguru/config/keys";
import {StorageService} from "./shared/services/storage.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'profeshDashboard';
  subscription: any;

  constructor(private translateService: TranslateService, private router: Router, private storageService: StorageService) {
    this.initLanguage();
    this.setRoot();
  }

  ngOnInit(): void {
    this.subscription = this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe(() => window.scrollTo(0, 0));
    console.log("%cATTENTION: CONSOLE USAGE NOTICE", "font-size: 24px; color: red; font-weight: bold;");
    console.log(
      "%cPlease be aware that the console is a tool for developers only.\n" +
      "Executing unknown scripts or commands may compromise system security.\n" +
      "Exercise caution and avoid executing any code unless you fully understand its implications.\n" +
      "Report any suspicious activity or potential security breaches to the appropriate authorities.\n" +
      "Stay vigilant and prioritize the security of your devices and personal data.",
      "font-size: 16px;"
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setRoot(): void {
    if (!this.storageService.getItem(keys.user)) {
      this.router.navigateByUrl('/login');
    }
  }

  /**
   * Init main language
   */
  initLanguage(): void {
    let lang = this.storageService.getItem(keys.lang);
    if (!lang) {
      this.storageService.setItem(keys.lang, 'en');
      lang = 'en';
    }
    this.translateService.use(lang);
  }
}
