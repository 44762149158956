import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatDialogModule} from "@angular/material/dialog";
import {LayoutModule} from "./layout/layout.module";
import {NavigationService} from "./services/navigation.service";



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatDialogModule,
    LayoutModule
  ],
  exports: [
    MatDialogModule,
    LayoutModule
  ],
  providers: [
    NavigationService
  ]
})
export class CanguruModule { }
