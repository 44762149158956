import { Injectable } from '@angular/core';
import {HttpService} from "../../../@canguru/services/http.service";
import {Observable} from "rxjs";
import {api} from "../config/api";
import {LinksForm, MobileLinksResponse} from "../interfaces/api/content/mobileLinks";

@Injectable({
  providedIn: 'root'
})
export class MobileLinksService {

  constructor(private http: HttpService) { }

  getLinks(): Observable<MobileLinksResponse> {
    return this.http.get(api.getMobileLinks)
  }

  updateLinks(form: LinksForm): Observable<MobileLinksResponse> {
    return this.http.post(api.updateMobileLinks, form)
  }
}
