<div class="menu-padding">
  <div class="top-toolbar">
    <h1 @fadeInRight>{{'app.title' | translate}}</h1>
  </div>
  <div @fadeInUp class="menu mt-10 p-4">
    <div class="top sidenav-items">
      <p class="menu-title text-secondary">{{'menu.title' | translate}}</p>
      <div class="flex-auto">
        <app-sidenav-item *ngFor="let item of navigationService.items;"
                          [item]="item"
                          [level]="0"></app-sidenav-item>
      </div>
      <mat-divider></mat-divider>
      <app-sidenav-item *ngFor="let item of navigationService.bottomItems"
                        [item]="item"
                        [level]="0"></app-sidenav-item>
    </div>
    <mat-menu #menu="matMenu" class="menu-logout">
      <button mat-menu-item (click)="logout()">
        <mat-icon [icIcon]="icLogout"></mat-icon>
        <span>{{'menu.logout' | translate}}</span>
      </button>
    </mat-menu>
    <div class="bottom">
      <a class="profile-button" mat-button [matMenuTriggerFor]="menu">
        <div class="user-button d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center justify-content-center">
            <mat-icon [icIcon]="icProfile"></mat-icon>
            <div class="profile-label">
              <p class="mb-0 name text-primary">
                {{authService.currentUser?.fullname}}
              </p>
              <p class="mb-0 text-secondary">{{authService.currentUser?.email}}</p>
            </div>
          </div>
          <mat-icon class="dropdown-icon" [icIcon]="icDropDown"></mat-icon>
        </div>
      </a>
    </div>
  </div>
</div>
