import { Injectable } from '@angular/core';
import {HttpService} from "../../../@canguru/services/http.service";
import {api} from "../config/api";
import {Observable} from "rxjs";
import {DashboardResponse} from "../interfaces/api/content/dashboard";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpService) { }

  /**
   * Get dashboard list.
   */
  getDashboard(): Observable<DashboardResponse> {
    return this.http.get(api.dashboard) as Observable<DashboardResponse>;
  }
}
