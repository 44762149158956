import {Injectable} from '@angular/core';
import {HttpService} from "../../../@canguru/services/http.service";
import {Observable} from "rxjs";
import {api} from "../config/api";
import {UsersResponse} from "../interfaces/api/content/users";
import {UserDetails} from "../interfaces/userDetails";
import {MatTableDataSource} from "@angular/material/table";
import {UtilService} from "./util.service";
import moment from "moment/moment";
import {CharityService} from "./charity.service";

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  usersTableData: MatTableDataSource<UserDetails> = new MatTableDataSource();
  isLoading = false;
  isError = false;
  currentPage = 0;
  totalData = 0;
  pageSize = 10;
  searchTerm = '';
  selectedFilter: string|number = '';
  sortBy: string = '';
  order: string = '';
  filters: string[] = ['active', 'banned']

  constructor(private utilService: UtilService, private http: HttpService, private charityService: CharityService) {
  }

  /**
   * Get users list.
   */
  getUsers(page: number, search: string, pageSize: number): Observable<UsersResponse> {
    let url = `${api.users}?page=${page}&per_page=${pageSize}&search=${search}`;
    if (this.selectedFilter) url += `&${this.selectedFilter}=1`;
    if (this.sortBy) url += `&sort_by=${this.sortBy}&order=${this.order}`;
    return this.http.get(url) as Observable<UsersResponse>;
  }

  /**
   * Get profile picture
   * @param user
   */
  getProfileSource(user: UserDetails) {
    if (user.profile_images?.length) return user.profile_images[0];
    return 'assets/images/default.webp';
  }

  /**
   * get users list.
   * @param loadMore
   */
  getUsersList(loadMore = false) {
    this.isLoading = true;
    this.isError = false;
    // if (this.currentPage === 0 && !this.charityService.data.length) this.charityService.getCharitiesList();
    this.getUsers((this.currentPage + 1), this.searchTerm, this.pageSize).subscribe({
      next: (res) => {
        if (res.result === 'success') {
          this.usersTableData.data = loadMore ? [...this.usersTableData.data, ...res.content.data] : res.content.data;
          this.totalData = res.content.total;
          if (loadMore) this.currentPage = this.currentPage + 1;
        }
      },
      error: (err) => {
        this.isError = true;
        if (!this.utilService.isProduction) console.log(err);
      }
    }).add(() => {
      this.isLoading = false;
    });
  }



  /**
   * On Active/Banned state changed event.
   * @param item
   * @param key
   */
  changeActiveBannedState(item: UserDetails, key: 'active' | 'banned') {
    this.isLoading = true;
    const form = {
      status: +item[key] === 1 ? 0 : 1,
      user_id: item.id
    }
    this.http.post(key === 'active' ? api.activateUser : api.banUser, form).subscribe({
      next: () => {
        const idx = this.usersTableData.data.findIndex(user => user.id === item.id);
        if (idx > -1) {
          this.usersTableData.data[idx][key] = '1';
          this.usersTableData.data[idx][key === 'active' ? 'banned' : 'active'] = '0';
        }
        this.utilService.displayToast('User updated successfully!');
      }, error: (err) => {
        if (!this.utilService.isProduction) console.log(err)
      }
    }).add(() => {
      this.isLoading = false;
    });
  }


  /**
   * Remove selected user using user id.
   * @param user_id
   */
  removeUser(user_id: number) {
    this.isLoading = true;
    this.http.post(api.deleteUser, {user_id}).subscribe({
      next: () => {
        const temp = [...this.usersTableData.data];
        const idx = temp.findIndex(item => item.id === user_id);
        temp.splice(idx, 1);
        this.usersTableData.data = temp;
        this.utilService.displayToast('User removed successfully!');
      }, error: (err) => {
        if (!this.utilService.isProduction) console.log(err);
      }
    }).add(() => {
      this.isLoading = false;
    });
  }


  /**
   * verify selected user using user id.
   * @param user_id
   */
  verifyUser(user_id: number) {
    this.isLoading = true;
    this.http.post(api.verifyUser, {user_id}).subscribe({
      next: () => {
        const temp = [...this.usersTableData.data];
        const idx = temp.findIndex(item => item.id === user_id);
        temp[idx].email_verified_at = moment().format();
        this.usersTableData.data = temp;
        this.utilService.displayToast('User verified successfully!');
      }, error: (err) => {
        if (!this.utilService.isProduction) console.log(err);
      }
    }).add(() => {
      this.isLoading = false;
    });
  }

  /**
   * Reset data.
   */
  reset() {
    this.usersTableData = new MatTableDataSource();
    this.currentPage = 0;
    this.totalData = 0;
    this.pageSize = 10;
    this.searchTerm = '';
  }

  /**
   * Get user details.
   * @param id
   */
  getUser(id: number) {
    return this.http.get(`${api.users}?user_id=${id}`);
  }

  /**
   * Unlock user.
   * @param user_id
   */
  unlockUser(user_id: number) {
    this.isLoading = true;
    this.http.post(api.unlockUser, {user_id}).subscribe({
      next: () => {
        const temp = [...this.usersTableData.data];
        const idx = temp.findIndex(item => item.id === user_id);
        temp[idx].unlock_date = undefined;
        this.usersTableData.data = temp;
        this.utilService.displayToast('User unlocked successfully!');
      }, error: (err) => {
        if (!this.utilService.isProduction) console.log(err);
      }
    }).add(() => {
      this.isLoading = false;
    });
  }

  /**
   * Filter data by charity.
   * @param filter
   */
  filterData(filter: string|number) {
    this.currentPage = 0;
    this.selectedFilter = filter;
    this.getUsersList();
  }
}
